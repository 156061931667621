import React, { useState } from 'react';
import useOutsideAlerter from '../../../../../../hooks/useOutsideAlerter';

import styles from './Dropdown.module.scss';
import { onEnterPress } from 'utils/onEnterPress';
type Option = {
  label: string;
  value: string | number;
};
type Props = {
  value: string | number;
  options: Option[];
  onClick: (event: any) => void;
};

const Dropdown: React.FC<Props> = ({ options, value, onClick }) => {
  const [isVisible, setVisible] = useState(false);
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => setVisible(false));
  const onDropdownClick = () => setVisible(!isVisible);
  const onMenuItemClick = (event: any) => {
    setVisible(false);
    onClick(Number(event.target.value));
  };
  return (
    <div className={styles.root} ref={wrapperRef}>
      <button
        onClick={onDropdownClick}
        className={styles.button}
        aria-expanded={isVisible}
      >
        <span className={styles.carretContainer}>
          <span className={styles.carret} />
        </span>
        <span className={styles.value}>{value}</span>
      </button>
      {isVisible && (
        <menu className={styles.menu}>
          {options.map(option => {
            return (
              <li key={option.value} className={styles.menuItem}>
                <button
                  value={option.value}
                  onClick={onMenuItemClick}
                  onKeyDown={e =>
                    onEnterPress(e, () => onMenuItemClick(Number(e)))
                  }
                >
                  {option.label}
                </button>
              </li>
            );
          })}
        </menu>
      )}
    </div>
  );
};

export default Dropdown;
